import { Box, Button, Typography } from '@branch-messenger/willow-ui'
import { useNavigate } from '@tanstack/react-router'
import { FC, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { Checkbox } from '@/components/checkbox'
import { Loader } from '@/components/loader'
import { useTerms } from '@/queries/registration'
import { TermsDetails } from '@/types/registration'

import { TermsAndPoliciesAgreementContainer, TermsContainer } from './styles'

type AgreedTerms = TermsDetails & { agreed: boolean }

export type AgreedTermsFormValues = {
  agreedTerms: AgreedTerms[]
}

export const Terms: FC = () => {
  const navigate = useNavigate()
  const { data: terms } = useTerms()

  const { watch, control } = useForm<AgreedTermsFormValues>({
    defaultValues: {
      agreedTerms: terms?.map(term => ({ ...term, agreed: false })) || [],
    },
  })

  const { fields } = useFieldArray({
    control,
    name: 'agreedTerms',
  })

  const agreedTerms = watch('agreedTerms', fields)
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentField = agreedTerms[currentIndex]

  const proceed = () => {
    const nextTerm = agreedTerms.find(term => !term.agreed)
    if (nextTerm) {
      const nextIndex = agreedTerms.findIndex(
        term => term.display_name === nextTerm.display_name
      )
      setCurrentIndex(nextIndex)
    } else {
      navigate({ to: '/setup/create-link' })
    }
  }

  if (!terms || !currentField)
    return (
      <Box $direction="column" $justify="center" $align="center">
        <Loader size="lg" />
      </Box>
    )

  return (
    <Box $direction="column">
      <Box $display="flex" $justify="center" $mb={4}>
        <Typography $size="xs" $italicized $color="textMuted">
          {`${currentIndex + 1} of ${agreedTerms.length}`}
        </Typography>
      </Box>
      <TermsContainer $isMobile={isMobile}>
        <iframe src={currentField.url} title={currentField.display_name} />
      </TermsContainer>
      <TermsAndPoliciesAgreementContainer>
        <Box $mr={8}>
          <Controller
            name={`agreedTerms.${currentIndex}.agreed`}
            control={control}
            render={({ field: { onChange } }) => (
              <Checkbox
                id={currentField.url}
                checked={currentField.agreed}
                onChanged={onChange}
                mode="circular"
                size="large"
              />
            )}
          />
        </Box>
        <Typography>
          {`By checking here, I agree that I have read, understood, and consent to ${currentField.display_name}.`}
        </Typography>
      </TermsAndPoliciesAgreementContainer>
      <Box $mt={8}>
        <Button onClick={proceed} disabled={!currentField.agreed}>
          {currentField.agreed ? 'Continue' : 'Agree to continue'}
        </Button>
      </Box>
    </Box>
  )
}
