import { FC, useState } from 'react'

import { Terms } from './Terms'
import { TermsOverview } from './TermsOverview'

export const TermsAndPoliciesAgreement: FC = () => {
  const [step, setStep] = useState(0)

  switch (step) {
    case 0:
      return <TermsOverview setStep={setStep} />
    case 1:
      return <Terms />
    default:
      return <TermsOverview setStep={setStep} />
  }
}
