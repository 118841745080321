import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { Description, Title, Typography } from '@/components/typography'
import { useOrg } from '@/selectors/useOrg'

export const GetStarted: FC = () => {
  const { orgName } = useOrg()
  const navigate = useNavigate()
  const getStarted = () => {
    navigate({ to: '/setup/branch-informational' })
  }
  const login = () => {
    navigate({ to: '/login' })
  }

  return (
    <Box direction="column" align="start">
      <Title>Fast pay, your way with {orgName}.</Title>
      <Description size="sm" css={{ mt: '$16' }}>
        {`Don't wait for your money—get paid fast and securely to either your
        debit card or the Branch App, a fee-free digital bank account from
        Evolve Bank & Trust. With Branch, you gain access to a faster, simpler
        way to spend and manage your money.`}
      </Description>
      <Button css={{ margin: '$16 0 $40 0' }} onClick={getStarted}>
        Get Started
      </Button>
      <Typography size="sm" color="muted">
        Already have an account?
      </Typography>
      <Button mode="link" css={{ mt: '$4' }} onClick={login}>
        Log In {`>`}
      </Button>
    </Box>
  )
}
